import { ReactElement, useEffect, useState } from 'react';
import { Address } from '@stripe/stripe-js';

import {
  defaultBillingPeriod,
  selectAmounts,
  selectNumberOfLicenses,
  selectTrackGroup,
  setAmounts,
  setNumberOfLicenses,
  setTrackGroup,
} from '../../../features/signUp/signUpSlice';
import {
  useApplyCouponMutation,
  useGetAllPricesQuery,
  useLazyCalculateTaxQuery,
} from '../../../services/apiSlice';
import { store, useAppSelector } from '../../../stores/AppStore';

import { Skeleton } from '../../ui/skeleton';
import { handleError } from '../../../services/ErrorService';
import { getPrice, getPricePerLicense } from '../../../lib/utils';
import toast from 'react-hot-toast';
import FlipSwitch from '../../FlipSwitch';
import { BillingPeriod, TrackGroup } from '../../../types';
import { AcademicCapIcon, BookOpenIcon } from '@heroicons/react/outline';

interface OrderDetailsProperties {
  // paymentIntentId: string;
  address?: Address;
  isApplyCouponBlocked: boolean;
  setIsLoading: (value: boolean) => void;
}

const calculateTotal = (baseAmount: number, tax: number) => {
  const total = baseAmount + tax;
  store.dispatch(
    setAmounts({
      tax,
      total,
    }),
  );
};

const OrderDetails = ({
  address,
  isApplyCouponBlocked,
  setIsLoading,
}: OrderDetailsProperties) => {
  const [showContactUs, setShowContactUs] = useState(false);
  const numberOfLicenses = useAppSelector(selectNumberOfLicenses);
  const trackGroup = useAppSelector(selectTrackGroup);
  const amounts = useAppSelector(selectAmounts);
  const [couponCode, setCouponCode] = useState('');
  const { data: allPrices } = useGetAllPricesQuery();
  const [applyCoupon, applyCouponResponse] = useApplyCouponMutation();
  const [calculateTaxTrigger, { isFetching }] = useLazyCalculateTaxQuery();

  const calculateDiscount = async () => {
    setIsLoading(true);
    const updateResponse = await applyCoupon({
      amount: amounts.amount,
      couponCode,
    });
    if ('error' in updateResponse) {
      if (
        !('status' in updateResponse.error) ||
        updateResponse.error.status !== 404
      ) {
        handleError(updateResponse.error);
      }
      toast.error('Applying coupon code failed. Please try again.');
      setIsLoading(false);
      return 0;
    }

    store.dispatch(
      setAmounts({
        discount: updateResponse.data.discount_amount,
      }),
    );
    setIsLoading(false);
    return updateResponse.data.discount_amount ?? 0;
  };

  const calculateTax = async (taxableAmount: number) => {
    if (!address || !taxableAmount) {
      console.warn('Tax recalculation failed', { address, taxableAmount });
      return 0;
    }
    setIsLoading(true);
    const result = await calculateTaxTrigger({
      address,
      amount: taxableAmount,
    });
    if (!result.data || result.error) {
      handleError(result.error ?? new Error('No tax data received'), {
        displayedError:
          'Failed to calculate tax. Please try again in a minute.',
      });
      return 0;
    }
    store.dispatch(
      setAmounts({
        tax: result.data.tax_amount_exclusive,
      }),
    );
    setIsLoading(false);
    return result.data.tax_amount_exclusive;
  };

  const selectedBillingPeriod: BillingPeriod =
    (amounts?.billingPeriod as BillingPeriod) || defaultBillingPeriod;

  // Effect to handle amount recalculation when prices are loaded ot the number of licenses or billing period changes
  useEffect(() => {
    if (!allPrices) {
      // NOTE: price data is missing until fetched, this is expected
      return;
    }
    if (numberOfLicenses > 3) {
      store.dispatch(
        setAmounts({
          amount: 0,
          total: undefined,
        }),
      );
      return;
    }
    const newAmount =
      100 *
      getPrice(
        numberOfLicenses,
        amounts.billingPeriod as BillingPeriod,
        allPrices,
      );
    // TODO: validate amount again
    store.dispatch(
      setAmounts({
        amount: newAmount,
      }),
    );
  }, [allPrices, numberOfLicenses, amounts.billingPeriod]);

  // Effect to handle discount, tax and total recalculation
  useEffect(() => {
    const calculateAll = async () => {
      // Calculate discount if applicable
      let baseAmount = amounts.amount;
      if (amounts.amount >= 50 && amounts.discountCode) {
        const discount = await calculateDiscount();
        baseAmount = baseAmount - discount;
      }

      if (!address) {
        return;
      }

      // Calculate tax based on post-discount amount
      const tax = await calculateTax(baseAmount);

      // Calculate final total
      calculateTotal(baseAmount, tax);
    };

    calculateAll();
  }, [amounts.amount, amounts.discountCode, address]);

  const handleApplyCoupon = () => {
    store.dispatch(
      setAmounts({
        discountCode: couponCode,
      }),
    );
  };

  const handleToggleBillingPeriod = (newSelection: 'left' | 'right') => {
    const newBillingPeriod: BillingPeriod =
      newSelection === 'left' ? 'month' : 'year';
    setBillingPeriod(newBillingPeriod);
  };

  const setBillingPeriod = (billingPeriod: BillingPeriod) => {
    store.dispatch(
      setAmounts({
        billingPeriod,
      }),
    );
  };

  const handleTrackGroupChange = (group: TrackGroup) => {
    try {
      store.dispatch(setTrackGroup(group));
      const newBillingPeriod: BillingPeriod =
        group === TrackGroup.ACTSATPrep
          ? 'year'
          : (amounts.billingPeriod as BillingPeriod) ?? 'month';
      if (newBillingPeriod !== amounts.billingPeriod) {
        setBillingPeriod(newBillingPeriod);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const TrackGroupCard = ({
    group,
    icon,
  }: {
    group: TrackGroup;
    icon: ReactElement;
  }) => {
    return (
      <div
        className={`
          relative flex flex-col items-center w-full rounded-xl border border-gray-300 p-4 aspect-square cursor-pointer
          hover:shadow-md hover:outline hover:outline-tttDefault hover:outline-[1px] hover:bg-tttDefault hover:bg-opacity-5
          ${
            trackGroup === group
              ? 'shadow-md outline outline-tttDefault outline-[1px] bg-tttDefault bg-opacity-5'
              : ''
          }
        `}
        onClick={() => handleTrackGroupChange(group)}
      >
        <div className="h-[3rem] flex items-center">
          <p className="text-gray-600 text-sm lg:text-base font-semibold line-clamp-2 text-center">
            {group}
          </p>
        </div>
        <div className="w-16 h-16 text-gray-500 mt-2">{icon}</div>
      </div>
    );
  };

  const getDisplayPrice = (numberOfLicenses: number) => {
    if (!allPrices || allPrices.length === 0) {
      return '?';
    }
    const price = getPricePerLicense(
      numberOfLicenses,
      selectedBillingPeriod,
      allPrices ?? [],
    );
    const displayPrice =
      price > 0
        ? price.toFixed(selectedBillingPeriod === 'month' ? 2 : 0)
        : '?';
    return displayPrice;
  };

  return (
    <div>
      <h1 className="text-2xl md:text-3xl font-medium w-full pb-2 md:pb-4">
        Step 1: Enroll Now
      </h1>
      <div
        data-testid="full-order-details"
        className="flex flex-col gap-4 md:w-[90%] md:bg-gray-100 text-gray-800 rounded-xl md:p-8 animate-slideInFromRight animate-slideOutToLeft"
      >
        <div className="text-normal text-gray-500">
          One subscription per student. Each student can follow one learning
          track. You can choose from 5th to 12th grade or ACT/SAT Math prep.
        </div>
        <div className="flex w-full lg:w-3/4 gap-4 justify-around mx-auto">
          <TrackGroupCard
            group={TrackGroup.ACTSATPrep}
            icon={<AcademicCapIcon />}
          />
          <TrackGroupCard
            group={TrackGroup.gradeLevelMath}
            icon={<BookOpenIcon />}
          />
        </div>
        <div data-testid="number-of-licenses" className="flex gap-2">
          <div className="mx-auto">
            <FlipSwitch
              disabledLeft={trackGroup === TrackGroup.ACTSATPrep}
              disabledLeftWarning={
                'ACT/SAT Math Prep courses are only available for an annual purchase option'
              }
              optionLeft="Monthly"
              optionRight="Annual"
              selected={selectedBillingPeriod === 'month' ? 'left' : 'right'}
              onChange={handleToggleBillingPeriod}
            />
            <p className="text-center text-gray-500">
              {selectedBillingPeriod === 'year'
                ? 'Two months free! 🎉'
                : 'Check our offer on yearly plans!'}
            </p>
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-2 max-w-full">
          {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="flex-none w-[80px] md:w-24">
              <div
                className={`
                      relative flex flex-col w-full rounded-xl border border-gray-300 p-2 aspect-square cursor-pointer
                      hover:shadow-md hover:outline hover:outline-tttDefault hover:outline-[1px] hover:bg-tttDefault hover:bg-opacity-5
                      ${
                        numberOfLicenses === index + 1
                          ? 'shadow-md outline outline-tttDefault outline-[1px] bg-tttDefault bg-opacity-5'
                          : ''
                      }
                    `}
                onClick={() => {
                  store.dispatch(setNumberOfLicenses(index + 1));
                  setShowContactUs(index === 3);
                }}
              >
                <input
                  type="hidden"
                  id={`license-${index + 1}`}
                  data-testid={`license-${index + 1}`}
                  value={index + 1}
                  className="w-5 h-5 place-self-start absolute cursor-pointer"
                />
                <label
                  htmlFor={`license-${index + 1}`}
                  className="flex flex-col gap-2 text-center text-l md:text-xl w-full mx-auto mt-0 mb-auto cursor-pointer"
                >
                  {index === 3 ? '4+' : index + 1}{' '}
                  <span className="text-gray-500 text-sm mt-[-8px]">
                    Student{index > 0 ? 's' : ''}
                  </span>
                  <div className="w-full px-1">
                    <p className="rounded-full bg-blue-100 text-tttDefault text-sm py-0.5 px-1 break-words cursor-pointer">
                      <strong>${getDisplayPrice(index + 1)}</strong>{' '}
                      {index > 0 ? 'each' : ''}
                    </p>
                  </div>
                </label>
              </div>
            </div>
          ))}
        </div>
        {showContactUs && (
          <p className="text-normal text-gray-500 py-2">
            Contact us at{' '}
            <a
              href="mailto:help@thinktankprep.com?subject=More Licenses"
              className="text-tttDefault hover:underline"
            >
              help@thinktankprep.com
            </a>{' '}
            to set up your subscriptions.
          </p>
        )}
        {!showContactUs && (
          <>
            <p className="text-xs text-gray-500">
              Track selection takes place after purchase
            </p>
            <div className="flex flex-col gap-2 mt-auto">
              <label htmlFor="couponCode" className="font-medium">
                Discount Code
              </label>
              <div className="flex flex-row gap-2">
                <input
                  type="text"
                  id="couponCode"
                  className="border border-gray-300 rounded-md px-3 py-1.5 w-full"
                  placeholder="Enter your discount code"
                  onChange={(event) => setCouponCode(event.target.value)}
                />
                <button
                  data-testid={`apply-coupon-button`}
                  onClick={handleApplyCoupon}
                  disabled={
                    isApplyCouponBlocked ||
                    isFetching ||
                    applyCouponResponse.isLoading
                  }
                  className="
            px-6 py-1.5 rounded-full border border-tttDefault text-tttDefault font-medium
            transition-all duration-300 ease-in-out
            hover:shadow hover:outline hover:outline-tttDefault hover:outline-[1px] hover:bg-tttDefault hover:bg-opacity-5
            disabled:opacity-50 disabled:cursor-not-allowed
          "
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-1 w-full self-end text-sm text-gray-500">
              <p>Subtotal</p>
              <p
                className={`text-right ${
                  Number.isFinite(amounts?.amount)
                    ? 'text-gray-800'
                    : 'text-gray-300'
                }`}
              >
                {amounts?.amount === undefined || amounts?.amount === 1
                  ? '$XXX.xx'
                  : `$${(amounts.amount / 100).toFixed(
                      2,
                    )} / ${selectedBillingPeriod}`}
              </p>
              {amounts?.discount !== undefined && (
                <>
                  <p>Discount</p>
                  <div
                    data-testid={'discount-amount'}
                    className={`flex text-right justify-end items-center ${
                      Number.isFinite(amounts?.amount)
                        ? 'text-gray-800'
                        : 'text-gray-300'
                    }`}
                  >
                    {applyCouponResponse.isLoading ? (
                      <Skeleton className="w-8 h-[90%] rounded-full bg-tttDefault bg-opacity-50" />
                    ) : (
                      ` -$${(amounts.discount / 100).toFixed(2)}`
                    )}
                  </div>
                </>
              )}
              <p>Tax</p>
              <div
                data-testid={`tax-amount`}
                className={`flex text-right text-sm justify-end items-center ${
                  Number.isFinite(amounts?.tax)
                    ? 'text-gray-800'
                    : 'text-gray-300'
                }`}
              >
                {isFetching ? (
                  <Skeleton className="w-8 h-[90%] rounded-full bg-tttDefault bg-opacity-50" />
                ) : amounts?.tax === undefined ? (
                  '$XX.xx'
                ) : (
                  `$${(amounts.tax / 100).toFixed(2)}`
                )}
              </div>
              <p className="text-base md:text-lg font-medium text-gray-800">
                Total
              </p>
              <div
                className={`flex font-medium text-right justify-end items-center ${
                  Number.isFinite(amounts?.total)
                    ? 'text-base md:text-lg text-gray-800'
                    : 'text-gray-300'
                }`}
              >
                {isFetching ? (
                  <Skeleton className="w-8 h-[90%] rounded-full bg-tttDefault bg-opacity-50" />
                ) : amounts?.total === undefined ? (
                  '$XXX.xx'
                ) : (
                  `$${(amounts.total / 100).toFixed(
                    2,
                  )} / ${selectedBillingPeriod}`
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
