/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';

import { FilterType, thinkerChartEmptyText } from '../constants';

import {
  apiSlice,
  useGetStudentOrThinkerSummaryDataQuery,
  useGetThinkerResultsForMentorQuery,
} from '../services/apiSlice';

import Filter, { FilterValue } from '../components/Filter';
import { Loading, LoadingBlock } from '../components/Loading';
import StudentDrillDown from '../partials/StudentDrillDown';
import Welcome from '../components/Welcome';

const ThinkerScores: FC = () => {
  const [selectedThinker, setSelectedThinker] = useState<string>();

  const getThinkerDocument = (thinkerName?: string) => {
    if (!Array.isArray(thinkerResults) || thinkerResults.length === 0) {
      return;
    }
    return thinkerResults?.find((thinker) => thinker?.name === thinkerName);
  };
  const {
    data: thinkerResults,
    isLoading: isLoadingThinkerResults,
    isFetching: isFetchingThinkerResults,
    isError: isErrorThinkerResults,
  } = useGetThinkerResultsForMentorQuery();
  const thinkerDocument = getThinkerDocument(selectedThinker);
  const {
    data: thinkerResult,
    isFetching: isFetchingThinkerResult,
    isLoading: isLoadingThinkerResult,
  } = useGetStudentOrThinkerSummaryDataQuery(
    {
      studentId: thinkerDocument?.student ?? '',
      shouldFetchTrack: true,
    },
    {
      skip: !thinkerResults || thinkerDocument === undefined,
    },
  );

  const prefetchThinkerResult = apiSlice.usePrefetch(
    'getStudentOrThinkerSummaryData',
  );

  const thinkerFilterOptions = thinkerResults?.map((r) => ({
    label: r.name,
    value: r.name,
  }));

  useEffect(() => {
    if (!Array.isArray(thinkerResults) || thinkerResults.length === 0) {
      return;
    }
    setSelectedThinker(() => thinkerResults[0].name);
  }, [thinkerResults]);

  if (isLoadingThinkerResults) {
    return <LoadingBlock />;
  } else if (isErrorThinkerResults) {
    throw new Error('Failed to load class students data');
  }

  return (
    <div className="grid gap-2 md:gap-4">
      <div className="m-4 md:m-0">
        <Welcome />
      </div>
      <div
        className="
          relative
          grid gap-4 p-4 md:p-6
          w-full h-full items-center 
        bg-gray-100 rounded-xl
        "
      >
        <div className="absolute right-4">
          {isFetchingThinkerResults && <Loading />}
        </div>
        <h1 className="text-3xl font-medium">
          Your Thinker
          {Array.isArray(thinkerResults) && thinkerResults.length > 1
            ? 's'
            : ''}
        </h1>
        {Array.isArray(thinkerResults) && thinkerResults.length > 0 ? (
          <Filter
            filterType={'thinker' as FilterType}
            options={thinkerFilterOptions ?? []}
            selected={
              selectedThinker
                ? {
                    label: selectedThinker,
                    value: selectedThinker,
                  }
                : undefined
            }
            onSelect={(selectedFilter: FilterValue) => {
              setSelectedThinker(() => selectedFilter.label);
            }}
            onHover={(selectedFilter: FilterValue) => {
              prefetchThinkerResult({
                studentId:
                  getThinkerDocument(selectedFilter.label)?.student ?? '',
                shouldFetchTrack: true,
              });
            }}
          />
        ) : (
          <div>
            We couldn't find your Thinker at the moment. Please try again later
            or contact us if this issue persists.
          </div>
        )}
        {selectedThinker && (
          <StudentDrillDown
            shouldScrollIntoView={false}
            isLoading={isLoadingThinkerResult || isFetchingThinkerResult}
            isOpen={!!thinkerResult}
            result={thinkerResult}
            chartEmptyText={thinkerChartEmptyText}
          />
        )}
      </div>
    </div>
  );
};

export default ThinkerScores;
